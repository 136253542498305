<template lang="pug">
v-row.background
  v-col(cols="12")
    v-row
      v-col(cols="10")
      v-col(cols="2")
        LanguageSwitcher()
    v-row
      v-col(cols="12")
        v-row
          v-col.blur(cols="4" )
          v-col(cols="4")
            registryForm
          v-col(cols="4")
</template>
<script>
import registryForm from "../components/OrganizationRegistrationForm.vue";
import LanguageSwitcher from "../components/LanguageSwitcher";
export default {
  components: {
    registryForm,
    LanguageSwitcher,
  },
};
</script>
<style scoped>
  .background{
    background-color: rgb(245 245 245);;
  }
</style>>
