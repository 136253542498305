<template lang="pug">
  v-row
    v-col(cols="2" lg="3")
      v-select(:items="countriesCodes")
    v-col(cols="10" lg="9")
      v-text-field(:label="label" :required="required")
</template>
<script>
export default {
  data: () => ({
    countriesCodes:["+380","+48","+375"]
  }),
  props:{
    required: Boolean,
    label: String
  }
}
</script>