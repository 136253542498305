<template lang="pug">
  v-row
    v-col(cols="12")
      form
        v-text-field.required(:label="$t('askOrganizationName')" required)
        phoneInput.required(required=true 
        :label="$t('personalPhoneNumberTitle')")
    v-row
      v-col(cols="2" offset="10")
        v-btn(@click="next") {{$t('next')}}
</template>
<script>
import phoneInput from "../components/based/PhoneNumberInput"
export default {
  components:{
    phoneInput
  },
  methods:{
    next: function(){
      this.$router.push("/cabinet/home")
    }
  }
}
</script>
